import COS from "cos-js-sdk-v5";
import {formatParams, request} from "./api";
import {Toast} from "vant";

export function dataURLtoBlob (dataurl) {
  var arr = dataurl.split(',');
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function  getTempSecretKey () {
  return new Promise((resolve, reject) => {
    request({
      url: '/comm/getTempSecretKey',
      data: JSON.stringify(formatParams())
    }).then(res => {
      resolve(res)
    }).catch(err => {
      console.log(err);
    })
  });
}

export function getQueryString(params) {
  var after = window.location.search;
  if (after) {
    let reg = new RegExp("(^|&)" + params + "=([^&]*)(&|$)", "i");
    var r = after.substr(1).match(reg);

    if (r != null) {
      return decodeURIComponent(r[2]);
    } else {
      return null;
    }
  }
}