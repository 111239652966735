import axios from "axios";
import {Toast} from "vant";
import store from "@/store";
import router from "@/router/router";

const PARTNER_H5 = "/h/"
const COMMON = "/h/comm"


const VISIT_TYPE = 'PARTNER_H5'
const VISIT_CORP_TYPE = 'PARTNER_CORP_H5'


// 使用自定义配置新建一个 axios 实例
export function request(config) {
    const instance = axios.create({
        headers: {'Content-Type': 'application/json;charset=utf-8'},
        // 基础的请求地址
        // baseURL: 'https://api.chuangfushuzi.com' + PARTNER_H5,
        baseURL: 'https://prod.chuangfushuzi.com' + PARTNER_H5,
        // baseURL: 'http://localhost:6060' + PARTNER_H5,
        // 设置超时时间 5s
        timeout: 60000,
        method: 'POST',

    });

    // axios拦截器
    instance.interceptors.request.use(config => {
        Toast.loading({
            message: '提交中...',
            forbidClick: true,
            duration: 0
        });
        //可在此显示加载图标
        //可在此做拦截登录
        return config;
    }, error => {
        console.log("error:" + error)
    })

    //响应拦截
    instance.interceptors.response.use(res => {
        Toast.clear();
        let data = res.data;
        switch (data.head.code) {
            case '10001':
                Toast(data.body.msg);
                return null;
            case '10002':
                Toast("账号已过期，请重新登录");
                window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3caf51bfc7052700&redirect_uri=http%3A%2F%2Fpartner.chuangfushuzi.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect"
                // router.replace("/login")
                return null;
            case '10005':
                window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3caf51bfc7052700&redirect_uri=http%3A%2F%2Fpartner.chuangfushuzi.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect"
                return null;
        }
        //请求成功后的拦截
        return data.body;
    }, error => {
        Toast("网络出现异常，请稍后再试");
        //请求失败的拦截
    })

    return instance(config);
}

//格式化参数
export function formatParams(data) {
    let partner = store.getters.partner || JSON.parse(localStorage.getItem("partner"))
    let token = store.getters.token || localStorage.getItem("token")
    let partnerId = null
    let partnerName = null
    if (partner != null && token != null) {
        partnerId = partner.id
        partnerName = partner.partnerName
    }
    data = {
        body: {...data},
        head: {visitType: VISIT_TYPE, token: token, partnerId: partnerId, partnerName: partnerName}
    }
    return data;
}

//格式化参数
export function formatParamsTo(data) {
    let partnerCorp = store.getters.partnerCorp || JSON.parse(localStorage.getItem("partnerCorp"))
    let token = store.getters.token || localStorage.getItem("token")
    let partnerCorpId = null
    let partnerCorpName = null
    if (partnerCorp != null && token != null) {
        partnerCorpId = partnerCorp.id
        partnerCorpName = partnerCorp.partnerCorpName
    }
    data = {
        body: {...data},
        head: {visitType: VISIT_CORP_TYPE, token: token, partnerCorpId: partnerCorpId, partnerCorpName: partnerCorpName}
    }
    return data;
}

/**
 * 生成一个用不重复的ID
 */
export function getUuiD() {
    let num = 0
    for (let i = 0; i < 5; i++) {
        num += (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    }
    return num
}

