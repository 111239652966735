<template>
  <div>
    <nav-bar :title="title"/>
    <GlobalBlock card>
      <van-form @submit="onSubmit">
        <van-field
            v-model="newPass"
            name="newPass"
            type="password"
            label="新密码"
            maxlength="18"
            placeholder="请输入新密码"
            :rules="[
              { required: true, message: '请输入密码' }]"
        />
        <van-field
            v-model="password"
            type="password"
            name="password"
            label="确认密码"
            maxlength="18"
            placeholder="确认密码"
            :rules="[{ required: true, message: '确认密码' }]"
        />
        <van-field name="mobile"
                   v-model="mobile"
                   type="digit"
                   maxlength="11"
                   label="手机号" placeholder="手机号"
                   readonly
                   :rules="[{ required: true, message: '请输入手机号' }]"/>
        <van-field
            name="authCode"
            v-model="authCode"
            center
            maxlength="6"
            type="digit"
            clearable
            label="短信验证码"
            placeholder="验证码"
            :rules="[{ required: true, message: '请输入验证码' }]">
          <template #button>
            <van-button v-show="show" size="small" type="primary" @click="passAuthCode" native-type="button"
                        style="width: 100px">发送验证码
            </van-button>
            <van-button v-show="!show" size="small" type="primary" native-type="button" style="width: 100px">
              {{ countDownTime }}s后重新发送
            </van-button>
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </GlobalBlock>
  </div>
</template>

<script>
import GlobalBlock from "@/components/Global-Block";
import NavBar from "@/components/nav/Nav-Bar";
import {Field, Form, Toast, Dialog} from 'vant';
import {formatParams, formatParamsTo, request} from "@/static/utils/api";

export default {
  name: "set-password",
  data() {
    return {
      show: true,
      title: '设置密码',
      newPass: '',
      pass: '',
      password: '',
      partnerType: '',
      id: 0,
      isPass: true,
      rawPass: '',
      authCode: '',
      mobile: '',
      countDownTime: '',
    }
  },
  components: {
    GlobalBlock,
    NavBar,
    [Form.name]: Form,
    [Field.name]: Field,
  },
  methods: {
    passAuthCode() {
      this.throttleFn();
      request({
        url: '/partnerCommon/getCode',
        data: JSON.stringify(formatParams({
          mobile: this.mobile
        }))
      }).then(res => {
        Toast("发送成功")
      }).catch(err => {
        console.log(err);
      })
    },
    onSubmit(values) {
      if (this.newPass.length < 6) {
        Toast('密码设置太短啦(6-18位数之间)');
        return false;
      }
      if (this.newPass !== this.password) {
        Toast('两次输入密码不一致，请检查');
        return false;
      }
      if (!/\d{6}/.test(values.authCode)) {
        Toast("请正确输入验证码")
        return false;
      }
        request({
          url: '/partnerCommon/updatePasswordById',
          data: JSON.stringify(this.partnerType === 0 ? formatParams({
            partnerType:this.partnerType,
            mobile: this.mobile,
            authCode: this.authCode,
            password: this.password
          }) : formatParamsTo({
            partnerType:this.partnerType,
            mobile: this.mobile,
            authCode: this.authCode,
            password: this.password
          }))
        }).then(res => {
          if (res) {
            if (this.partnerType === 0) {
              this.$store.commit('handlePartner', res);
              localStorage.removeItem("partnerCorp");
              this.$router.replace("/home");
            } else {
              this.$store.commit('handlePartnerCorp', res);
              localStorage.removeItem("partner");
              this.$router.replace("/home");
            }
          }
        }).catch(err => {
          console.log(err)
        })
    },
    //函数节流
    throttleFn() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.countDownTime = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.countDownTime > 0 && this.countDownTime <= TIME_COUNT) {
            this.countDownTime--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
  },
  mounted() {
    this.partnerType = parseInt(localStorage.getItem("partnerType"));
    if (this.partnerType === 0) {
      let partner = JSON.parse(localStorage.getItem("partner"));
      this.id = partner.id
      let mobile = partner.mobile.substring((partner.mobile.length - 6), partner.mobile.length)
      this.mobile = partner.mobile
      if (partner.password === mobile) {
        this.isPass = true;
        this.title = '设置密码'
      } else {
        this.isPass = false;
        this.title = '修改密码'
      }
    } else {
      let partnerCorp = JSON.parse(localStorage.getItem("partnerCorp"));
      this.id = partnerCorp.id
      this.mobile = partnerCorp.contactsMobile
      let mobile = partnerCorp.contactsMobile.substring((partnerCorp.contactsMobile.length - 6), partnerCorp.contactsMobile.length)
      if (partnerCorp.password === mobile) {
        this.isPass = true;
        this.title = '设置密码'
      } else {
        this.isPass = false;
        this.title = '修改密码'
      }
    }
  }
}
</script>

<style scoped>

</style>
