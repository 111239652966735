<template>
  <div>
    <van-nav-bar
        title="录入签名"
    />
    <div style="border: 1px solid #eee" ref="canvasBox">
      <canvas class="canvasId"/>
    </div>
    <div class="btnBox">
      <van-button round type="info" @click="clear">重签</van-button>
      <van-button round type="info" @click="save">确定</van-button>
    </div>
  </div>
</template>

<script>
import SignaturePad from 'signature_pad';
import {Button,Notify,NavBar,Toast } from 'vant'
import {dataURLtoBlob} from "@/static/utils/common";
import {getTempSecretKey} from "@/static/utils/common";
import COS from "cos-js-sdk-v5";
import {formatParams, getUuiD, request} from "@/static/utils/api";

export default {
  name: "signature",
  components: {
    [Button.name]:Button,
    [Notify.Component.name]: Notify.Component,
    [NavBar.name]:NavBar
  },
  data () {
    return {
      SignaturePad:null,
      config:{
        penColor: "#000000",   //笔刷颜色
        minWidth:3,       //最小宽度
      },
      routeTo:'',
      bucket: '',
      expiredTime: '',
      region: '',
      sessionToken: '',
      startTime: '',
      tmpSecretId: '',
      tmpSecretKey: ''
    }
  },
  mounted() {
    this.getCanvas();
    getTempSecretKey().then(res => {
      this.bucket = res.bucket;
      this.expiredTime = res.expiredTime;
      this.region = res.region;
      this.sessionToken = res.sessionToken;
      this.startTime = res.startTime;
      this.tmpSecretId = res.tmpSecretId;
      this.tmpSecretKey = res.tmpSecretKey;
    })
  },
  methods: {
    getCanvas() {
      var canvas = document.querySelector('.canvasId');
      this.signaturePad = new SignaturePad(canvas, this.config);
      canvas.height = 200;
      canvas.width = this.$refs.canvasBox.clientWidth;
    },
    //保存
    save(){
      if(this.signaturePad.isEmpty()){
        Notify({ type: 'danger', message: '电子签名不能为空' });
      }else{
        let _this = this;
        const cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: _this.tmpSecretId,
              TmpSecretKey: _this.tmpSecretKey,
              SecurityToken: _this.sessionToken,
              // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
              StartTime: _this.startTime, // 时间戳，单位秒，如：1580000000
              ExpiredTime: _this.expiredTime, // 时间戳，单位秒，如：1580000000
            });
          }
        });
        let file = dataURLtoBlob(this.signaturePad.toDataURL())
        cos.putObject({
          Bucket: this.bucket, /* 必须 */
          Region: this.region,     /* 存储桶所在地域，必须字段 */
          Key: 'TMP/' + getUuiD() + ".png",              /* 必须 */
          StorageClass: 'STANDARD',
          Body: file, // 上传文件对象
          onProgress: function (progressData) {
          }
        }, function (err, data) {
          if (!err && data) {
            Toast.clear()
            let signImgUrl = "https://" + data.Location
            _this.uploadSign(signImgUrl)
          } else {
            console.log(err)
            Toast.fail('上传签名失败，请联系客服');
          }
        })
      }
    },
    //清除
    clear() {
      //清除画布内容
      this.signaturePad.clear();

    },
    uploadSign(signImgUrl) {
        request({
          url: '/partner/addPartnerSign',
          data: JSON.stringify(formatParams({
            signImgUrl: signImgUrl
          }))
        }).then(res => {
          this.$store.commit('handlePartner',res);
          var self = this;
          setTimeout(function () {
            Toast('录入签名成功');
            if (self.$route.path === "/personAuthStatus") {
              window.location.reload()
            } else {
              self.$router.replace('/personAuthStatus');
            }
          }, 1000);
          // Toast("发送成功")
        }).catch(err => {
          console.log(err);
        })
      }
  }
}
</script>

<style scoped lang="scss">
.btnBox {
  padding: 10px;
  text-align: center;
  /deep/ .van-button--info {
    height: 30px;
    padding: 0 30px;
    &:first-child {
      margin-right: 30px;
    }
  }
}
</style>