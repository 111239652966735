<template>
  <div>
    <NavBar :title="title"/>
    <global-block card>
      <van-form @submit="onSubmit">
        <van-field
            v-model="reportName"
            name="reportName"
            label="姓名"
            placeholder="请输入姓名"
            :rules="[{ required: true, message: '请输入姓名' }]"
        />
        <van-field
            v-model="mobile"
            name="mobile"
            label="手机号码"
            placeholder="请输入手机号码"
            type="digit"
            maxlength="11"
            :rules="[{ required: true, message: '请输入手机号' }]"/>
        <van-field
            v-model="cooperationDirection"
            name="cooperationDirection"
            label="合作方向"
            placeholder="请输入报备个人的合作方向"
            :rules="[{ required: true, message: '请输入报备个人的合作方向' }]"
        />
        <van-field
            v-model="remark"
            name="remark"
            rows="2"
            autosize
            label="备注"
            type="textarea"
            maxlength="50"
            placeholder="请输入报备个人经营范围，用于业务员了解"
            show-word-limit
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </global-block>
  </div>
</template>

<script>
import {Form, Field, Toast, Dialog} from 'vant';
import GlobalBlock from "@/components/Global-Block";
import NavBar from "@/components/nav/Nav-Bar";
import {formatParams, formatParamsTo, request} from "@/static/utils/api";

export default {
  data() {
    return {
      title: '个人报备',
      reportName: '',
      mobile: '',
      remark: '',
      cooperationDirection: '',
      reportType: 0,
      partnerType: '',
    };
  },
  components: {
    [Form.name]: Form,
    [Field.name]: Field,
    GlobalBlock,
    NavBar
  },
  mounted() {
    document.title = this.title
    this.partnerType = parseInt(localStorage.getItem("partnerType"));
  },
  methods: {
    onSubmit(values) {
      if (!/\d{11}/.test(values.mobile)) {
        Toast("手机号输入有误")
        return false;
      }
      values.reportType = this.reportType;
      values.partnerType = this.partnerType;
      request({
        url: '/partnerReport/addPartnerReport',
        data: JSON.stringify(this.partnerType === 0 ? formatParams(values) : formatParamsTo(values))
      }).then(res => {
        if (res) {
          Dialog.alert({
            title: '提示',
            message: '报备成功！后续会有相关的业务员跟进，报备有效期限为三个月，三个月后则需要重新报备。',
          }).then(() => {
            this.$router.push({path: '/home', query: {partnerType: this.partnerType}});
          });
        }
      }).catch(err => {
        console.log(err)

      })
    },
    activated() {

    }
  },
}
</script>

<style scoped>

</style>