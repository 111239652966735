<template>
  <div>
    <nav-bar :title="title"/>
    <global-block card>
      <div v-show="inviteShow">
        <van-cell title="如何邀请？"/>
        <van-grid :column-num="5" :border="false">
          <van-grid-item icon="guide-o" text="发送邀请"/>
          <van-grid-item icon="arrow"/>
          <van-grid-item icon="user-circle-o" text="用户注册"/>
          <van-grid-item icon="arrow"/>
          <van-grid-item icon="records" text="填邀请码"/>
        </van-grid>
      </div>
      <van-search v-show="!inviteShow"
                  show-action
                  v-model="invitationCode"
                  maxlength="4"
                  placeholder="请填写上级邀请码"
      >
        <template #action>
          <div @click="addUpPartner">确 认</div>
        </template>
      </van-search>
    </global-block>
    <global-block card>
      <van-tabs v-model="active">
        <van-tab title="推荐个人">
          <van-cell><strong style="padding: 0 15px 0 15px">名称<span style="float: right">电话</span></strong></van-cell>
          <van-pull-refresh v-model="partnerRefresh" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="getLowerPartner"
            >
              <van-cell v-for="(item,index) in partner" :key="index" :title="item.partnerName">{{ item.mobile }}
              </van-cell>

            </van-list>
            <van-empty v-if="partnerIsEmpty"
                       class="custom-image"
                       image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
                       description="这里是空的~~"
            />
          </van-pull-refresh>
        </van-tab>
        <van-tab title="推荐企业">
          <van-cell><strong style="padding: 0 15px 0 15px">企业名称<span style="float: right">联系人</span></strong></van-cell>
          <van-pull-refresh v-model="partnerCorpRefresh" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="getLowerPartner"
            >
              <van-cell v-for="(item,index) in partnerCorp" :key="index" :title="item.partnerCorpName">
                {{ item.contacts }}
              </van-cell>
            </van-list>
            <van-empty v-if="partnerCorpIsEmpty"
                       class="custom-image"
                       image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
                       description="这里是空的~~"
            />
          </van-pull-refresh>
        </van-tab>
      </van-tabs>

    </global-block>
  </div>
</template>

<script>
import GlobalBlock from "@/components/Global-Block";
import NavBar from "@/components/nav/Nav-Bar";
import {formatParams, formatParamsTo, request} from "@/static/utils/api";
import {Toast} from "vant";

export default {
  name: "my-referrer",
  components: {
    GlobalBlock,
    NavBar
  },
  data() {
    return {
      title: '我的推荐',
      active: 0,
      upPartnerIsCorpId: '',
      upPartnerIsCorpName: '',
      upPartnerType: '',
      invitationCode: '',
      inviteShow: true,
      partner: [],   //被邀请的合伙人  下级
      partnerCorp: [],   //被邀请的合伙人  下级
      loading: false,
      finished: false,
      remark: '',
      cooperationDirection: '',
      reportType: 1,
      partnerType: '',
      partnerRefresh: false,
      partnerCorpRefresh: false,
      partnerCorpIsEmpty: false,
      partnerIsEmpty: false,
    };
  },
  methods: {
    getLowerPartner() {
      request({
        url: '/partnerCommon/getLowerPartner',
        data: JSON.stringify(this.partnerType === 0 ? formatParams({partnerType: this.partnerType}) : formatParamsTo({partnerType: this.partnerType}))
      }).then(res => {
        if (res) {
          if (this.partnerRefresh) {
            this.partner = [];
            this.partnerCorp = [];
            this.partnerRefresh = false;
          }
          if (this.partnerCorpRefresh) {
            this.partnerCorp = [];
            this.partner = [];
            this.partnerCorpRefresh = false;

          }
          if (res.partners.length > 0) {
            for (let i = 0; i < res.partners.length; i++) {
              this.partner.push(res.partners[i]);
            }
          } else {
            this.partnerIsEmpty = true;
          }
          if (res.partnerCorps.length > 0) {
            for (let i = 0; i < res.partnerCorps.length; i++) {
              this.partnerCorp.push(res.partnerCorps[i]);
            }
          } else {
            this.partnerCorpIsEmpty = true;
          }
          this.loading = false;
          this.finished = true;
        }
      }).catch(err => {
        console.log(err)

      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getLowerPartner();
    },
    addUpPartner() {
      request({
        url: '/partnerCommon/addUpPartner',
        data: JSON.stringify(
            this.partnerType === 0 ?
                formatParams({
                  partnerType: this.partnerType,
                  invitationCode: this.invitationCode
                }) :
                formatParamsTo({
                  partnerType: this.partnerType,
                  invitationCode: this.invitationCode
                }))
      }).then(res => {
        if (res) {
          Toast("填写邀请码成功")
          if (this.partnerType === 0) {
            this.$store.commit('handlePartner', res);
          } else {
            this.$store.commit('handlePartnerCorp', res);
          }
          this.inviteShow = true;
        }
      }).catch(err => {
        console.log(err);
      })
    }
  },
  mounted() {
    document.title = this.title
    this.partnerType = parseInt(localStorage.getItem("partnerType"));
    if (this.partnerType === 0) {
      let partner = JSON.parse(localStorage.getItem("partner"));
      if (partner.upPartnerIsCorpId != null && partner.upPartnerIsCorpName != null && partner.upPartnerType != null) {
        this.upPartnerIsCorpId = partner.upPartnerIsCorpId;
        this.upPartnerIsCorpName = partner.upPartnerIsCorpName;
        this.upPartnerType = partner.upPartnerType;
        this.inviteShow = true;
      } else {
        this.inviteShow = false;
      }
    } else {
      let partnerCorp = JSON.parse(localStorage.getItem("partnerCorp"));
      if (partnerCorp.upPartnerIsCorpId != null && partnerCorp.upPartnerIsCorpName != null && partnerCorp.upPartnerType != null) {
        this.upPartnerIsCorpId = partnerCorp.upPartnerIsCorpId;
        this.upPartnerIsCorpName = partnerCorp.upPartnerIsCorpName;
        this.upPartnerType = partnerCorp.upPartnerType;
        this.inviteShow = true;
      } else {
        this.inviteShow = false;
      }
    }
  }
}
</script>

<style scoped>

</style>