<template>
  <div>
    <NavBar :title="title"/>
    <global-block card>
      <van-tabs v-model="active">
        <van-tab title="我的报备">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
              <van-collapse v-model="activeNames">
                <van-cell v-for="(item,index) in partnerReports" :key="index">
                  <template #title>
                    <span class="custom-title">{{ item.reportName }} </span>
                    <van-tag type="success" v-if="item.reportType === 0"> 个人</van-tag>
                    <van-tag type="primary" v-if="item.reportType === 1">企业</van-tag>
                    <van-tag type="success" plain  v-if="item.status === 2" style="float: right">已报备</van-tag>
                    <van-tag type="danger" plain  v-if="item.status === 0" style="float: right">拒绝合作</van-tag>
                    <van-tag type="warning" plain  v-if="item.status === 3" style="float: right">洽谈中</van-tag>
                    <van-tag color="#7232dd" plain  v-if="item.status === 4" style="float: right">已接入</van-tag>
                  </template>
                  <template #label>
                    <span class="custom-title">时间：{{ item.reportDate }}</span><br>
                    <span class="custom-title">{{ item.remark }} </span><br>
                    <span class="custom-title">
                      <span v-if="item.reportType === 1">{{ item.contactsPost }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span v-if="item.reportType === 1">{{ item.contacts }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                       {{ item.mobile }}</span>
                  </template>
                </van-cell>
              </van-collapse>
            </van-list>
            <van-empty v-if="reportIsEmpty"
                       class="custom-image"
                       image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
                       description="这里是空的~~"
            />
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </global-block>
  </div>
</template>

<script>
import NavBar from "@/components/nav/Nav-Bar";
import GlobalBlock from "@/components/Global-Block";
import {formatParams, formatParamsTo, request} from "@/static/utils/api";
import {Toast} from "vant";

export default {
  name: "my-client",
  data() {
    return {
      title:'我的报备',
      active: 2,
      partnerReports: [],
      loading: false,
      finished: false,
      refreshing: false,
      activeNames: ['1'],
      partnerType: '',
      // partnerType:'企业',
      reportName:'测试企业',
      status:'',
      reportDate:'',
      reportIsEmpty:false,
    };
  },
  components: {
    NavBar,
    GlobalBlock
  },
  methods: {
    onLoad() {
      if (this.refreshing) {
        this.partnerReports = [];
        this.refreshing = false;
      }
      request({
        url: '/partnerReport/getPartnerReportByPartnerIsCorpId',
        data: JSON.stringify(this.partnerType === 0 ? formatParams({partnerType: this.partnerType}) : formatParamsTo({partnerType: this.partnerType}))
      }).then(res => {
        if (res) {
          if (res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              this.partnerReports.push(res[i]);
            }
          } else {
            this.reportIsEmpty = true;
          }
          this.loading = false;
          this.finished = true;
        }
      }).catch(err => {
        console.log(err)

      })
      this.loading = false;
      this.finished = true;
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
  },
  mounted() {
    document.title = this.title
    this.partnerType = parseInt(localStorage.getItem("partnerType"));
  }
}
</script>

<style lang="less">
//@import '../../common/style/var';

//.van-tag + .van-tag {
//  margin-left: @padding-xs;
//}
</style>