<template>
  <div>
    <nav-bar :title="title"/>
    <GlobalBlock card>
      <van-cell title="签署提示" label="为确保在线签约全流程的真实性、合法性，签署确认流程需核对签署操作为真实本人行为"/>
      <van-form @submit="onSubmit" >
        <van-field
            label="姓名"
            v-model="partnerName"
            readonly
        />
        <van-field
            label="身份证"
            v-model="idCard"
            readonly
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">确认</van-button>
        </div>
      </van-form>
    </GlobalBlock>
  </div>
</template>

<script>
import NavBar from "@/components/nav/Nav-Bar";
import GlobalBlock from "@/components/Global-Block";
import {Form, Field, Toast} from 'vant';
import {formatParams, request} from "@/static/utils/api";
export default {
  name: "sign-affirm",
  components:{
    NavBar,
    GlobalBlock,
    [Field.name]:Field,
    [Form.name]:Form
  },
  data() {
    return {
      title: '确认信息',
      partnerName:'',
      idCard:'',
    };
  },
  methods:{
    onSubmit() {
      request({
        url: '/contract/signContractByPartner',
        data: JSON.stringify(formatParams({
          path:this.$route.path
        }))
      }).then(res => {
        if (res) {
          let self = this;
          Toast.success('签署成功');

          setTimeout(function () {
            self.$router.replace('/home');
          }, 2000);

        }
      }).catch(err => {
        console.log(err)

      })
    }
  },
  mounted() {
    document.title = this.title
    let partner = JSON.parse(localStorage.getItem("partner"));
    this.partnerName = partner.partnerName;
    let temp = partner.idCard.substring(4, 14) // 03199901015 提取身份证的第5位(下标为4)到第14位（结束位为15）
    this.idCard=partner.idCard.replace(temp, '***********') // 将身份证中中间的11位替换成***********（11个*号）
  }
}
</script>

<style scoped>

</style>