<template>
  <div>
    <van-nav-bar
        title="首页"
    />
    <van-notice-bar left-icon="volume-o" :scrollable="false">
      <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
      >
        <van-swipe-item>{{ loginTitle }}</van-swipe-item>
        <!--        <van-notice-bar mode="link">技术是开发它的人的共同灵魂。</van-notice-bar>-->
        <van-swipe-item mode="link">下拉即可刷新</van-swipe-item>
      </van-swipe>

    </van-notice-bar>
    <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" mode="link"
                    v-if="isAudit !== 1 && isAudit !== 3" @click="viewAuthStatus">点击此处可查看认证进度
    </van-notice-bar>
    <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" mode="link" v-if="isAudit === 3"
                    @click="goAuth">点击此处前往认证
    </van-notice-bar>

    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <global-block card v-if="isAudit===1">
        <van-contact-card
            v-if="partnerType===0"
            type="edit"
            :name="partnerName"
            :tel="mobile"
            to="/myPersonData"
        />
        <div v-if="partnerType===1">
          <van-cell is-link title="企业名称：" :value="partnerCorpName" to="/myCorpData"/>
          <van-cell title="企业联系人电话:" :value="contactsMobile"/>
        </div>
      </global-block>
      <global-block card v-if="isAudit!==1">
        <van-cell title="认证状态：" is-link @click="toPersonIsCorp">
          <van-tag type="primary" plain size="medium" v-if="isAudit===2">审核中</van-tag>
          <van-tag type="danger" plain size="medium" v-if="isAudit===0">未通过审核</van-tag>
          <van-tag type="warning" plain size="medium" v-if="isAudit===3">未认证</van-tag>
        </van-cell>
      </global-block>
      <global-block card>
        <van-cell title="我的邀请码：">
          <van-tag type="success " size="medium" style="margin-right: 25px">{{ invitationCode }}</van-tag>
        </van-cell>
      </global-block>

      <!--      <global-block card>-->
      <van-divider/>
      <van-grid clickable :column-num="2" :gutter="5">
        <van-grid-item icon="records" text="客户报备" @click="showRoundCorner = true"/>
        <van-grid-item icon="friends-o" text="我的客户" to="/myClient"/>
        <van-grid-item icon="label-o" text="我的推荐" @click="isAudits('/myReferrer')"/>
        <van-grid-item icon="comment-o" text="我的报备" to="/myReport"/>
        <van-grid-item icon="bar-chart-o" text="我的收益" to="/myEarnings"/>
        <van-grid-item icon="orders-o" text="合作简介" @click="isAudits('/cooperation')"/>
      </van-grid>
      <!--      </global-block>-->

      <van-popup v-model="showRoundCorner" round position="bottom" :style="{ height: '30%' }">
        <global-block card>
          <van-grid clickable :column-num="2" :gutter="5" style="margin-top: 30px">
            <van-grid-item icon="user-o" text="个人报备" @click="isAudits('/personReport')"/>
            <van-grid-item icon="hotel-o" text="企业报备" @click="isAudits('/corpReport')"/>
          </van-grid>
        </global-block>
      </van-popup>
    </van-pull-refresh>

    <van-dialog v-model="agreementShow" title="请阅读以下相关内容" @confirm="signPreviewAgreement" confirmButtonText="我已知晓">
      <van-cell-group inset style="margin-top: 10px">
        <van-cell title="《诚信纳税承诺书》" @click="previewAgreement(0)"/>
        <van-cell title="《代开发票风险提示》" @click="previewAgreement(1)"/>
      </van-cell-group>
    </van-dialog>
  </div>

</template>

<script>
import GlobalBlock from "@/components/Global-Block";
// import Agreement from "@/views/agreement/agreement";
import store from "@/store";
import {Dialog, Toast, ImagePreview} from "vant";
import {formatParams, formatParamsTo, request} from "@/static/utils/api";

export default {
  components: {
    GlobalBlock,
    // Agreement
  },
  data() {
    return {
      isLoading: false,
      isAudit: 1,
      partnerName: '',
      mobile: '',
      invitationCode: '',
      showRoundCorner: false,
      partnerType: '',
      partnerCorpName: '',
      contactsMobile: '',
      loginTitle: '无通知',
      promise: '',
      riskWarning: '',
      agreementShow: false,
      previewAgreementUrl: '',
      previewAgreementShow: false,
      isContract: false,
    };
  },
  mounted() {
    document.title = "首页"
    this.partnerType = parseInt(localStorage.getItem("partnerType"));
    if (this.partnerType === 0) {
      this.getByPartnerId();
    } else if (this.partnerType === 1) {
      this.getByPartnerCorpId();
    }

  },
  methods: {
    signPreviewAgreement() {
      request({
        url: '/partner/signPreviewAgreement',
        data: JSON.stringify(formatParams())
      }).then(res => {
        if (res) {
          this.$store.commit('handlePartner', res);
          Toast.success('成功');
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getByPartnerCorpId() {
      request({
        url: '/partnerCorp/getByPartnerCorpId',
        data: JSON.stringify(formatParamsTo())
      }).then(res => {
        if (res) {
          // console.log(res)
          this.partnerCorpName = res.partnerCorpName;
          this.contactsMobile = res.contactsMobile;
          this.invitationCode = res.invitationCode;
          this.isAudit = res.isAudit;
          if (this.isAudit === 0) {
            this.loginTitle = "您的企业认证未通过审核，请重新提交"
          } else if (this.isAudit === 2) {
            this.loginTitle = "您的企业信息正在审核中..."
          } else if (this.isAudit === 3) {
            this.loginTitle = "您还未认证，请先认证企业"
          } else if (this.isAudit === 1) {
            this.loginTitle = "您登录的是企业合伙人"
          }
        } else {
          window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3caf51bfc7052700&redirect_uri=http%3A%2F%2Fpartner.chuangfushuzi.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect"
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getByPartnerId() {
      request({
        url: '/partner/getByPartnerId',
        data: JSON.stringify(formatParams())
      }).then(res => {
        if (res) {
          this.partnerName = res.partnerName;
          this.mobile = res.mobile;
          this.invitationCode = res.invitationCode;
          this.isAudit = res.isAudit;
          if (this.isAudit === 0) {
            this.loginTitle = "您的实名认证未通过审核，请重新提交"
          } else if (this.isAudit === 2) {
            this.loginTitle = "您的实名信息正在审核中..."
          } else if (this.isAudit === 3) {
            this.loginTitle = "您还未认证，请先实名认证"
          } else if (this.isAudit === 1) {
            if (res.promise == null || res.riskWarning == null) {
              this.agreementShow = true;
            } else {
              this.riskWarning = res.riskWarning;
              this.promise = res.promise;
            }

            this.loginTitle = "您登录的是个人合伙人"
          }
        } else {
          window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3caf51bfc7052700&redirect_uri=http%3A%2F%2Fpartner.chuangfushuzi.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect"
        }
      }).catch(err => {
        console.log(err)
      })
    },
    toPersonIsCorp() {
      if (this.partnerType === 0) {
        this.$router.replace("/myPersonData");
      } else if (this.partnerType === 1) {
        this.$router.replace("/myCorpData");
      }

    },
    isAudits(path) {
      console.log(path)
      if (this.partnerType === 0) {
        if (this.promise == null || this.riskWarning == null) {
          this.agreementShow = true;
        }
        switch (this.isAudit) {
          case 0:
            Dialog.confirm({
              title: '提示',
              message: '您的认证信息未通过审核，点击确认去查看审核详情',
            }).then(() => {
              this.$router.replace("/personAuthStatus");
            }).catch(() => {
              console.log("cancel")
            });
            break;
          case 1:
            if (path !== '/cooperation') {
              request({
                url: '/contract/getPartnerContract',
                data: JSON.stringify(formatParams({partnerType: this.partnerType}))
              }).then(res => {
                if (res) {
                  if (res.length > 0) {
                    this.$router.replace(path);
                  } else {
                    Dialog({message: '抱歉！您还未签署合伙人合同，请前往合作简介签署合伙人合同'});
                  }
                }
              }).catch(err => {
                console.log(err)
              })
            } else {
              this.$router.replace(path);
            }
            break;
          case 2:
            Dialog({message: '抱歉！您的认证信息还在审核中，审核成功后才能操作...'});
            break;
          case 3:
            Dialog.confirm({
              title: '提示',
              message: '您还未提交身份信息认证，请先提交身份信息认证',
            }).then(() => {
              this.$router.replace("/personAuth");
            }).catch(() => {
              console.log("cancel")
            });
            break;
          default:
            Toast('系统错误！请联系客服');
            break;
        }
      } else {
        switch (this.isAudit) {
          case 0:
            Dialog.confirm({
              title: '提示',
              message: '您的认证信息未通过审核，点击确认去查看审核详情',
            }).then(() => {
              this.$router.replace("/corpAuthStatus");
            }).catch(() => {
              console.log("cancel")
            });
            break;
          case 1:
            this.$router.replace(path);
            break;
          case 2:
            Dialog({message: '抱歉！您的认证信息还在审核中，审核成功后才能操作...'});
            break;
          case 3:
            Dialog.confirm({
              title: '提示',
              message: '您还未提交企业认证信息，请先提交企业认证信息',
            }).then(() => {
              this.$router.replace("/corpAuth");
            }).catch(() => {
              console.log("cancel")
            });
            break;
          default:
            Toast('系统错误！请联系客服');
            break;
        }
      }
    },
    onRefresh() {
      if (this.partnerType === 0) {
        this.getByPartnerId();
        this.isLoading = false;
      } else {
        this.getByPartnerCorpId();
        this.isLoading = false;
      }
    },
    viewAuthStatus() {
      if (this.partnerType === 0) {
        this.$router.replace("/personAuthStatus");
      } else {
        this.$router.replace("/corpAuthStatus");
      }
    },
    goAuth() {
      if (this.partnerType === 0) {
        this.$router.replace("/personAuth");
      } else {
        this.$router.replace("/corpAuth");
      }
    },
    previewAgreement(type) {
      if (type === 0) {
        this.previewAgreementUrl = "https://devchuang-1302946799.cos.ap-guangzhou.myqcloud.com/SIGN_AGREEMENT/promise.jpg"
      } else {
        this.previewAgreementUrl = "https://devchuang-1302946799.cos.ap-guangzhou.myqcloud.com/SIGN_AGREEMENT/risk_warning.jpg"
      }
      ImagePreview([
        this.previewAgreementUrl
      ]);
    },
  },
  beforeRouteLeave(to, from, next) {
    this.showRoundCorner = false;
    let path = [];
    path.push(this.$route.path)
    sessionStorage.setItem("lastRouters", JSON.stringify(path));
    sessionStorage.setItem("lastRouter", this.$route.path);
    next()
  }
}
</script>

<style lang="less">
@import '../../common/style/var';

.notice-swipe {
  height: 40px;
  line-height: 40px;
}

.van-doc-demo-block {
  &__title {
    margin: 0;
    padding: 32px 16px 16px;
    color: @van-doc-text-light-blue;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
  }

  &__card {
    margin: 12px 12px 0;
    overflow: hidden;
    border-radius: 8px;
  }

  &__title + &__card {
    margin-top: 0;
  }

  &:first-of-type {
    .van-doc-demo-block__title {
      padding-top: 20px;
    }
  }
}
</style>
