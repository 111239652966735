<template>
  <div>
    <nav-bar :title="title"/>
    <GlobalBlock card>
      <nav-step :active="2" :authTitle="authTitle"/>
    </GlobalBlock>
    <GlobalBlock card>
      <van-row type="flex" justify="center">
        <van-image
            round
            width="8rem"
            height="8rem"
            :src="auditStatus"
        />
      </van-row>
    </GlobalBlock>
    <GlobalBlock card style="margin-top: 30px">
      <van-row type="flex" justify="center">
        <span :style="style">{{ authText }}</span>
      </van-row>
    </GlobalBlock>

    <GlobalBlock card>
      <van-button type="primary" block v-show="buttons1" @click="anewSubmit">重新提交</van-button>
      <van-button type="primary" block v-show="buttons2" @click="getByPartnerCorpId">刷新</van-button>
      <van-button type="primary" block v-show="buttons3" :to="{path:'/home',query:{partnerType:partnerType}}">下一步</van-button>
    </GlobalBlock>
    <global-block card>
      <van-button block type="default" @click="logout">退出登录</van-button>
    </global-block>
    <van-popup v-model="show" position="bottom" :style="{ height: '40%' }">
      <signature/>
    </van-popup>
  </div>
</template>

<script>
import {Dialog, Image as VanImage, Toast} from 'vant';
import NavBar from "@/components/nav/Nav-Bar";
import GlobalBlock from "@/components/Global-Block";
import NavStep from "@/components/nav/Nav-Step";
import {formatParamsTo, request} from "@/static/utils/api";
import signature from "@/views/signature/signature";

export default {
  name: "corp-auth-status",
  components: {
    NavBar,
    GlobalBlock,
    [VanImage.name]: VanImage,
    NavStep,
    signature
  },
  data() {
    return {
      title: '认证状态',
      show: false,
      buttons1: false,
      buttons2: false,
      buttons3: false,
      auditStatus: 'https://chuangfu-1302946799.cos.ap-guangzhou.myqcloud.com/UI/in-review.png',
      authTitle: '审核中',
      authText: "您的企业正在审核中...",
      style: 'color:#008df0',
      partnerCorpId: '1',
      isSignAuth: 1,
      isAudit: "0",
      partnerType:'1'
    };
  },
  mounted() {
    document.title = this.title
    this.getByPartnerCorpId();
  },
  methods: {
    getByPartnerCorpId() {
      request({
        url: '/partnerCorp/getByPartnerCorpId',
        data: JSON.stringify(formatParamsTo())
      }).then(res => {
        if (res) {
          this.partnerId = res.id;
          this.$store.commit('handlePartnerCorp', res)
          switch (res.isAudit) {
            case 0:
              this.auditStatus = "https://chuangfu-1302946799.cos.ap-guangzhou.myqcloud.com/UI/not-pass.png";
              this.buttons1 = true;
              this.buttons2 = false;
              this.buttons3 = false;
              this.authTitle = "未通过";
              if (res.remark) {
                this.authText = '';
                let remark = JSON.parse(res.remark);
                for (let i = 0; i < remark.length; i++) {
                  if (remark.length === 1 && remark[i].value === 4) {
                    this.isSignAuth = 0;
                  }
                  this.authText += remark[i].name + "、"
                }
                this.authText = this.authText.substr(0, this.authText.length - 1);
              }
              this.style = 'color:#FF0000FF';
              break;
            case 1:
              this.auditStatus = "https://chuangfu-1302946799.cos.ap-guangzhou.myqcloud.com/UI/pass.png";
              this.buttons3 = true
              this.buttons1 = false
              this.buttons2 = false
              this.authTitle = "已通过";
              this.authText = '恭喜您！您已通过审核'
              break;
            case 2:
              this.auditStatus = "https://chuangfu-1302946799.cos.ap-guangzhou.myqcloud.com/UI/in-review.png";
              this.buttons2 = true
              this.buttons1 = false
              this.buttons3 = false
              break;
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    anewSubmit() {
      this.$router.push({path: '/corpAuth', query: {isAudit: this.isAudit}});
    },
    logout() {
      Dialog.confirm({
        title: '提示',
        message: '您确定要退出登录吗？',
      })
          .then(() => {
            localStorage.clear();
            window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3caf51bfc7052700&redirect_uri=http%3A%2F%2Fpartner.chuangfushuzi.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect"
          }).catch(() => {
        console.log("cancel")
      });
    }
  }
}
</script>

<style scoped>

</style>