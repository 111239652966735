<template>
  <div>
    <div style="width: 100%;text-align: center;margin: 100px 0 30px 0">
      <img src="@/static/images/logo.png" style="width: 140px;height: 140px; border-radius: 100%;">
    </div>
    <GlobalBlock card>

      <van-form @submit="onSubmit">
        <van-tabs v-model="partnerType">
          <van-tab title="个人登录">
            <van-field name="mobile"
                       v-model="mobile"
                       type="digit"
                       maxlength="11"
                       label="手机号" placeholder="手机号"
                       :rules="[{ required: true, message: '请输入手机号' }]"/>
            <van-field
                v-if="passLogin"
                v-model="password"
                type="password"
                name="password"
                label="密码"
                maxlength="18"
                placeholder="请输入密码"
                :rules="[{ required: true, message: '请输入密码' }]"
            />
            <van-field
                v-if="!passLogin"
                name="authCode"
                v-model="authCode"
                center
                maxlength="6"
                type="digit"
                clearable
                label="短信验证码"
                placeholder="验证码"
                :rules="[{ required: true, message: '请输入验证码' }]">
              <template #button>
                <van-button v-show="show" size="small" type="primary" @click="passAuthCode" native-type="button"
                            style="width: 100px">发送验证码
                </van-button>
                <van-button v-show="!show" size="small" type="primary" native-type="button" style="width: 100px">
                  {{ countDownTime }}s后重新发送
                </van-button>
              </template>
            </van-field>
          </van-tab>
          <van-tab title="企业登录">
            <van-field name="mobile"
                       v-model="mobile"
                       type="digit"
                       maxlength="11"
                       label="手机号" placeholder="手机号"
                       :rules="[{ required: true, message: '请输入手机号' }]"/>
            <van-field
                v-if="passLogin"
                v-model="password"
                type="password"
                name="password"
                label="密码"
                maxlength="18"
                placeholder="请输入密码"
                :rules="[{ required: true, message: '请输入密码' }]"
            />
            <van-field
                v-if="!passLogin"
                name="authCode"
                v-model="authCode"
                center
                maxlength="6"
                type="digit"
                clearable
                label="短信验证码"
                placeholder="验证码"
                :rules="[{ required: true, message: '请输入验证码' }]">
              <template #button>
                <van-button v-show="show" size="small" type="primary" @click="passAuthCode" native-type="button"
                            style="width: 100px">发送验证码
                </van-button>
                <van-button v-show="!show" size="small" type="primary" native-type="button" style="width: 100px">
                  {{ countDownTime }}s后重新发送
                </van-button>
              </template>
            </van-field>
          </van-tab>
        </van-tabs>

        <div style="margin: 10px 0 0 10px;">
          <p @click="passLoginClick" style="font-size: 12px;color: #1989fa;text-decoration: underline">{{loginTypeTitle}}</p>
        </div>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">登录 / 注册</van-button>
        </div>
      </van-form>
    </GlobalBlock>
    <!--    <van-popup v-model="popupRegister" round position="bottom" :style="{ height: '30%' }" :closeable="true">-->
    <!--      <global-block card>-->
    <!--        <van-grid clickable :column-num="2" :gutter="5" style="margin-top: 40px">-->
    <!--          <van-grid-item icon="user-o" text="个人注册" :to="{path:'/register',query:{partnerType:0}}"/>-->
    <!--          <van-grid-item icon="hotel-o" text="企业注册" :to="{path:'/register',query:{partnerType:1}}"/>-->
    <!--        </van-grid>-->
    <!--      </global-block>-->
    <!--    </van-popup>-->
  </div>
</template>

<script>
import GlobalBlock from "@/components/Global-Block";
import {Field, Form, Toast, Dialog} from 'vant';
import {formatParams, request} from "@/static/utils/api";
import {getQueryString} from "@/static/utils/common";

export default {
  name: "login",
  data() {
    return {
      partnerType: '1',
      show: true,
      popupRegister: false,
      password:'',
      mobile: '',
      authCode: '',
      countDownTime: '',
      timer: null,
      code: '',
      passLogin:false,
      loginTypeTitle:'密码登录'
    };
  },
  components: {
    GlobalBlock,
    [Form.name]: Form,
    [Field.name]: Field,
  },
  methods: {
    onSubmit(values) {
      if (!/\d{11}/.test(values.mobile)) {
        Toast("手机号输入有误")
        return false;
      }
      if (!this.passLogin) {
        if (!/\d{6}/.test(values.authCode)) {
          Toast("请正确输入验证码")
          return false;
        }
      }

      values.partnerType = parseInt(this.partnerType);
      values.code = this.code;
      values.password = this.password;
      values.passLogin = this.passLogin
      request({
        url: '/partnerCommon/loginOrRegister',
        data: JSON.stringify(formatParams(values))
      }).then(res => {
        if (res) {
          if (this.partnerType === 0) {
            this.$store.commit('handlePartner', res);
            this.$store.commit('handlePartnerToken', res);
            localStorage.removeItem("partnerCorp");
            this.$router.replace("/home");
          } else {
            this.$store.commit('handlePartnerCorp', res);
            this.$store.commit('handlePartnerCorpToken', res);
            localStorage.removeItem("partner");
            this.$router.replace("/home");
          }
        }
      }).catch(err => {
        console.log(err)
      })
    },
    passAuthCode() {
      if (this.mobile === '') {
        Toast("请输入手机号码")
        return false;
      }
      this.throttleFn();
      request({
        url: '/partnerCommon/getCode',
        data: JSON.stringify(formatParams({
          mobile: this.mobile
        }))
      }).then(res => {
        Toast("发送成功")
      }).catch(err => {
        console.log(err);
      })
    },
    //函数节流
    throttleFn() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.countDownTime = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.countDownTime > 0 && this.countDownTime <= TIME_COUNT) {
            this.countDownTime--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },
    passLoginClick() {
      this.passLogin = !this.passLogin
      this.loginTypeTitle = this.passLogin?'短信验证码登录':'密码登录'
    }
  },
  mounted() {
    this.code = getQueryString("code");
    if (this.code == null) {
      window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3caf51bfc7052700&redirect_uri=http%3A%2F%2Fpartner.chuangfushuzi.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect"
    }
    console.log(document.URL)
    window.addEventListener('popstate', function () {
      history.pushState(null, null, document.URL)
    })
    // console.log(this.count ++)
    // localStorage.clear()
    // history.pushState(null, null, "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3caf51bfc7052700&redirect_uri=http%3A%2F%2Fpartner.chuangfushuzi.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect")
    // window.addEventListener(
    //     'popstate',
    //     function() {
    //       history.pushState(null, null, "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3caf51bfc7052700&redirect_uri=http%3A%2F%2Fpartner.chuangfushuzi.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect")
    //     },
    //     false
    // )
  },
}
</script>

<style scoped>

</style>
