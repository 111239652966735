<template>
  <div>
    <NavBar :title="title"/>
    <GlobalBlock card>
      <van-cell title="简介介绍"
                style="text-align: center;font-size: 22px;margin-top: 10px">
      </van-cell>
      <van-cell style="text-align: center;font-size: 12px;text-indent: 2em;line-height: 35px">
        合作简介：“企规宝”是企业财税合规管理工具化服务平台，依托信息技术，应用业务流程管理外包（BPO）工具，通过重构优化交易控制风险、降低财税和用工成本，帮助企业完成低成本合规并账，实现财税阳光化。平台是由三个国家机构（中国扶贫发展中心、海外留学人才联合会、《炎黄世界》）和一个学术委员会（华南理工大学风险投资研究中心企业合规专业委员会）共同发起，联合资深企业合规专家、财税专家、法律专家、信息技术实施专家、双一流高校博导共同运营。
      </van-cell>
    </GlobalBlock>
    <global-block card>
      <van-tabs v-model="active">
        <van-tab title="我的合同">
          <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
            >
              <van-collapse v-model="activeNames">
                <van-cell v-for="(item,index) in contracts" :key="index">
                  <template #title>
                    <div class="van-ellipsis">{{ item.contractName }}</div>
                  </template>
                  <van-button type="primary" size="small" v-if="item.signerUrl != null" @click="view(item.signerUrl)">
                    查看
                  </van-button>
                </van-cell>
              </van-collapse>
            </van-list>
            <van-empty description="您没有已签署的合同" v-show="contractShow"/>
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </global-block>
    <GlobalBlock card v-if="partnerType === 0">
      <van-button type="primary" block to="/signPreview">签约合同</van-button>
    </GlobalBlock>
    <!--      <van-divider>我的合同列表</van-divider>-->

    <!--      <van-cell title="合同名称"><van-button type="primary" size="small">查看</van-button></van-cell>-->
    <!--      </GlobalBlock>-->

  </div>
</template>

<script>
import NavBar from "@/components/nav/Nav-Bar";
import GlobalBlock from "@/components/Global-Block";
import {formatParams, formatParamsTo, request} from "@/static/utils/api";
import {Toast} from "vant";

export default {
  name: "cooperation",
  components: {
    NavBar,
    GlobalBlock
  },
  data() {
    return {
      title: '合作简介',
      active: 1,
      activeNames: ['1'],
      contracts: [],
      loading: false,
      finished: false,
      refreshing: false,
      contractName: '',
      partnerType: '',
      contractShow: false,
    };
  },
  methods: {
    onLoad() {
      if (this.refreshing) {
        this.contracts = [];
        this.refreshing = false;
      }
      request({
        url: '/contract/getPartnerContract',
        data: JSON.stringify(this.partnerType === 0 ? formatParams({partnerType: this.partnerType}) : formatParamsTo({partnerType: this.partnerType}))
      }).then(res => {
        if (res) {
          if (res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              this.contracts.push(res[i]);
            }
          } else {
            this.contractShow = true;
          }
          this.loading = false;
          this.finished = true;
        }
      }).catch(err => {
        console.log(err)
      })
      this.loading = false;
      this.finished = true;
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    view(url) {
      window.open(url)
    }
  },
  mounted() {
    document.title = this.title
    this.partnerType = parseInt(localStorage.getItem("partnerType"));
  },
  beforeRouteLeave(to, from, next) {
    let lastRouters = JSON.parse(sessionStorage.getItem("lastRouters"));
    lastRouters.push(this.$route.path)
    lastRouters =  Array.from(new Set(lastRouters));
    sessionStorage.setItem("lastRouters",JSON.stringify(lastRouters));
    next()
  }
}
</script>

<style scoped>

</style>
