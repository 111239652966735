<template>
  <div>
    <van-steps :active="active">
      <van-step>基本注册</van-step>
      <van-step>实名认证</van-step>
      <van-step>{{authTitle}}</van-step>
    </van-steps>
  </div>
</template>

<script>
export default {
  name: "Nav-step",
  data() {
    return {
    };
  },
  props:{
    active:{
      type:Number
    },
    authTitle:{
      type:String,
      default:'审核中'
    }
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>