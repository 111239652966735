import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from "@/views/login/login";
import Home from "@/views/home/home";
import PersonAuth from "@/views/register/person/person-auth";
import CorpAuth from "@/views/register/corp/corp-auth";
import PersonAuthStatus from "@/views/authStatus/person-auth-status";
import CorpAuthStatus from "@/views/authStatus/corp-auth-status";
import MyPersonData from "@/views/my/data/my-person-data";
import MyCorpData from "@/views/my/data/my-corp-data";
import PersonReport from "@/views/report/person/person-report";
import CorpReport from "@/views/report/corp/corp-report";
import MyReferrer from "@/views/my/referrer/my-referrer";
import MyReport from "@/views/my/report/my-report";
import Cooperation from "@/views/cooperation/cooperation";
import SignPreview from "@/views/sign/preview/sign-preview";
import SignAffirm from "@/views/sign/affirm/sign-affirm";
import MyClient from "@/views/my/client/my-client";
import MyEarnings from "@/views/my/earnings/my-earnings";
import SetPassword from "@/views/set/set-password";
import store from "@/store";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        component: Home,
        meta: {
            title: '首页'
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            title: '企规宝合伙人-登录',
            allowBack: false
        }
    },
    {
        path: '/personAuth',
        component: PersonAuth,
        meta: {
            title: '个人认证'
        }
    },
    {
        path: '/corpAuth',
        component: CorpAuth,
        meta: {
            title: '企业认证'
        }
    },
    {
        path: '/personAuthStatus',
        component: PersonAuthStatus,
        meta: {
            title: '个人认证状态'
        }
    },
    {
        path: '/corpAuthStatus',
        component: CorpAuthStatus,
        meta: {
            title: '企业认证状态'
        }
    },
    {
        path: '/myPersonData',
        component: MyPersonData,
        meta: {
            title: '我的信息'
        }
    },
    {
        path: '/myCorpData',
        component: MyCorpData,
        meta: {
            title: '我的企业'
        }
    },
    {
        path: '/personReport',
        component: PersonReport,
        meta: {
            title: '个人报备'
        }
    },
    {
        path: '/corpReport',
        component: CorpReport,
        meta: {
            title: '企业报备'
        }
    },
    {
        path: '/myReferrer',
        component: MyReferrer,
        meta: {
            title: '我的推荐'
        }
    },
    {
        path: '/myReport',
        component: MyReport,
        meta: {
            title: '我的报备'
        }
    },
    {
        path: '/cooperation',
        component: Cooperation,
        meta: {
            title: '合作简介'
        }
    },
    {
        path: '/signPreview',
        component: SignPreview,
        meta: {
            title: '合同预览'
        }
    },
    {
        path: '/signAffirm',
        component: SignAffirm,
        meta: {
            title: '确认信息'
        }
    },
    {
        path: '/myClient',
        component: MyClient,
        meta: {
            title: '我的客户'
        }
    },
    {
        path: '/myEarnings',
        component: MyEarnings,
        meta: {
            title: '我的收益'
        }
    },
    {
        path: '/setPassword',
        component: SetPassword,
        meta: {
            title: '设置密码'
        }
    },
    {
        path: '*',
        component: Home,
        meta: {
            title: '首页',
        }
    },
]


const router = new VueRouter({
    routes,
    base: process.env.BASE_URL,
    mode:'history',
    linkActiveClass: 'active',
    scrollBehavior:() =>{
        history.pushState(null,null,document.URL);
    }
})
//
// let path = [];
// router.beforeResolve((to, from, next) => {
//     // path.push(from.path);
//
//     // if (path.length > 0) {
//     //     for (let i = 0; i < path.length; i++) {
//     //         if (path[i] === '/' || path === 'home') {
//     //
//     //         }
//             console.log(from.path)
//     //     }
//     // }
//     // store.commit('routerPath', from.path);
//     next();
// });

//新版本记录路由导航控制台会报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
    return VueRouterPush.call(this, to).catch(err => err)
}


export default router
