<template>
  <div>
    <NavBar :title="title"/>
    <GlobalBlock card>

      <van-image :round="true"
                 v-for="(item,index) in previewImg" :key="index"
                 radius="5px"
          width="100%"
          height="100%"
          :src="item"
      />
    </GlobalBlock>
    <global-block card style="padding-bottom: 30px">
      <van-button type="primary" :round="true" block to="/signAffirm">确认签署</van-button>
    </global-block>
  </div>
</template>

<script>
import NavBar from "@/components/nav/Nav-Bar";
import GlobalBlock from "@/components/Global-Block";
import {formatParams, request} from "@/static/utils/api";
import {Toast} from "vant";

export default {
  name: "sign-preview",
  components: {
    NavBar,
    GlobalBlock
  },
  data() {
    return {
      title: '合同预览',
      previewImg:[]
    };
  },
  methods:{
    getContractPreview() {
      request({
        url: '/contractTemplate/getSignPreview',
        data: JSON.stringify(formatParams({
          path:this.$route.path
        }))
      }).then(res => {
        if (res) {
          this.previewImg = res;
        }
      }).catch(err => {
        console.log(err)

      })
    },
  },
  mounted() {
    document.title = this.title
    this.getContractPreview()
  },
  beforeRouteLeave(to, from, next) {
    let lastRouters = JSON.parse(sessionStorage.getItem("lastRouters"));
    console.log(lastRouters)
    lastRouters.push(this.$route.path)
    lastRouters =  Array.from(new Set(lastRouters));
    sessionStorage.setItem("lastRouters",JSON.stringify(lastRouters));
    next()
  }
}
</script>

<style scoped>

</style>