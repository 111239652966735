<template>
  <div>
    <nav-bar :title="title"/>
    <GlobalBlock card>
      <van-field
          v-model="partnerCorpName"
          name="partnerCorpName"
          label="企业名称"
          readonly
      />
      <van-field
          v-model="creditCode"
          name="creditCode"
          label="信用代码"
          readonly
      />
      <van-field
          v-model="contacts"
          name="contacts"
          label="联系人"
          readonly
      />
      <van-field
          v-model="contactsMobile"
          name="contactsMobile"
          label="联系人电话"
          readonly
      />
      <van-collapse v-model="activeNames">
        <van-collapse-item title="营业执照" name="1"><img :src="businessLicense" style="width: 100%;height: auto"/>
        </van-collapse-item>
      </van-collapse>
      <van-cell title="设置密码" is-link to="/setPassword" :id="id" :partnerType="1"/>
      <div style="margin: 16px;">
        <van-button block type="info" plain @click="logout">退出登录</van-button>
      </div>
    </GlobalBlock>
  </div>
</template>

<script>
import {Form, Field, Dialog} from 'vant';
import NavBar from "@/components/nav/Nav-Bar";
import GlobalBlock from "@/components/Global-Block";

export default {
  name: "my-data",
  data() {
    return {
      title:'我的企业',
      activeNames: ['1'],
      partnerCorpName: '张三',
      creditCode: 'XXXXXXXXXXXXXXXX',
      contacts: '',
      contactsMobile: '',
      businessLicense: '',
      id:0
    };
  },
  components: {
    GlobalBlock,
    NavBar,
    [Form.name]: Form,
    [Field.name]: Field
  },
  mounted() {
    document.title = this.title
    let partnerCorp = JSON.parse(localStorage.getItem("partnerCorp"));
    this.partnerCorpName = partnerCorp.partnerCorpName;
    this.creditCode = partnerCorp.creditCode;
    this.contacts = partnerCorp.contacts;
    this.id = partnerCorp.id;
    this.contactsMobile = partnerCorp.contactsMobile;
    this.businessLicense = partnerCorp.businessLicense;
  },
  methods: {
    logout() {
      Dialog.confirm({
        title: '提示',
        message: '您确定要退出登录吗？',
      })
      .then(() => {
          localStorage.clear();
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3caf51bfc7052700&redirect_uri=http%3A%2F%2Fpartner.chuangfushuzi.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect"
      }).catch(() => {
        console.log("cancel")
      });
    }
  }
}
</script>

<style scoped>

</style>
