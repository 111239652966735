<template>
  <div>
    <nav-bar :title="title"/>
    <GlobalBlock card>
      <van-form @submit="onSubmit" >
        <van-field name="uploader" label="上传营业执照">
          <template #input>
            <van-uploader :after-read="afterRead" name="FRONT" :max-size="isOverSize" accept="image/*">
              <img src="@/static/images/business-license.png" v-show="businessLicenseShow" style="width: 100%;height: 150px">
              <img :src="businessLicense" v-show="!businessLicenseShow" style="width: 100%;height: 150px">
              <p style="color: #FF0000FF" v-show="businessLicenseAuth">营业执照未通过审核</p>
            </van-uploader>
          </template>
        </van-field>
        <van-field
            v-model="partnerCorpName"
            name="partnerCorpName"
            label="企业名称"
            placeholder="企业名称"
            :rules="[{ required: true, message: '请输入企业名称' }]"
        />
        <p style="color: #FF0000FF; background: #fff;text-indent: 8em;font-size: 14px" v-show="partnerCorpNameShow">营业执照未通过需要重新填写</p>
        <van-field
            v-model="creditCode"
            name="creditCode"
            label="信用代码"
            placeholder="信用代码"
            :rules="[{ required: true, message: '请输入信用代码' }]"
        />
        <p style="color: #FF0000FF; background: #fff;text-indent: 8em;font-size: 14px" v-show="creditCodeShow">营业执照未通过需要重新填写</p>
        <van-field
            v-model="contacts"
            name="contacts"
            label="联系人"
            placeholder="企业联系人"
            :rules="[{ required: true, message: '请输入企业联系人' }]"
        />
        <p style="color: #FF0000FF; background: #fff;text-indent: 8em;font-size: 14px" v-show="contactsShow">企业联系人未通过</p>
        <van-field
            v-model="contactsMobile"
            name="contactsMobile"
            label="联系电话"
            type="digit"
            maxlength="11"
            placeholder="企业联系电话"
            :rules="[{ required: true, message: '请输入企业联系电话' }]"
        />
        <p style="color: #FF0000FF; background: #fff;text-indent: 8em;font-size: 14px" v-show="contactsShow">企业联系人未通过需要重新填写</p>
        <van-field
            v-model="authCode"
            center
            clearable
            name="authCode"
            label="短信验证码"
            type="digit"
            maxlength="6"
            placeholder="验证码"
            :rules="[{ required: true, message: '请输入短信验证码' }]"
        >
          <template #button>
            <van-button v-show="show" size="small" type="primary" @click="passAuthCode" native-type="button" style="width: 100px">发送验证码</van-button>
            <van-button v-show="!show" size="small" type="primary" native-type="button" style="width: 100px" disabled>{{countDownTime}}s后重新发送</van-button>
          </template>
        </van-field>
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">下一步</van-button>
        </div>
      </van-form>
    </GlobalBlock>
  </div>
</template>

<script>
import NavBar from "@/components/nav/Nav-Bar";
import GlobalBlock from "@/components/Global-Block";
import {Form, Toast} from 'vant';
import {formatParamsTo, getUuiD, request} from "@/static/utils/api";
import COS from "cos-js-sdk-v5";
import {getTempSecretKey} from "@/static/utils/common";

export default {
  name: "corp-register",
  data() {
    return {
      title:'企业认证',
      businessLicenseShow:true,
      businessLicenseAuth:false,
      businessLicense:'',
      partnerCorpNameShow:false,
      creditCodeShow:false,
      contactsShow:false,
      show: true,
      countDownTime:'',
      partnerCorpName:'',
      creditCode:'',
      contacts:'',
      contactsMobile:'',
      authCode:'',
      isAudit:''
    };
  },
  components: {
    [Form.name]:Form,
    NavBar,
    GlobalBlock,
  },
  methods:{
    onSubmit(values) {
      if (this.businessLicense === '') {
        Toast("请上传营业执照")
        return false;
      }
      values.businessLicense = this.businessLicense;
      request({
        url: '/partnerCorp/addPartnerCorp',
        data: JSON.stringify(formatParamsTo(values))
      }).then(res => {
        if (res) {
          var self = this;
          setTimeout(function () {
            Toast("提交成功")
            self.$router.replace('/corpAuthStatus');
          }, 1000);
        }
      }).catch(err => {
        console.log(err);
      })
    },
    passAuthCode() {
      if (this.contactsMobile === '') {
        Toast("请输入联系人电话")
        return false;
      }
      if (!/\d{11}/.test(this.contactsMobile)) {
        Toast("手机号输入有误")
        return false;
      }
      this.throttleFn();
      request({
        url: '/partnerCorp/getCode',
        data: JSON.stringify(formatParamsTo({
          mobile:this.contactsMobile
        }))
      }).then(res => {
        Toast("发送成功")
      }).catch(err => {
        console.log(err);
      })
    },
    //函数节流
    throttleFn() {
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.countDownTime = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.countDownTime > 0 && this.countDownTime <= TIME_COUNT) {
            this.countDownTime--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000)
      }
    },

    afterRead(file, detail) {
      if (this.fileSize) {
        // 自定义加载图标
        Toast.loading({
          message: '上传中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });
        this.uploadBusinessLicense(file.file, detail.name);
      }
    },
    uploadBusinessLicense(file, type) {
      let _this = this;
      const cos = new COS({
        getAuthorization: function (options, callback) {
          callback({
            TmpSecretId: _this.tmpSecretId,
            TmpSecretKey: _this.tmpSecretKey,
            SecurityToken: _this.sessionToken,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: _this.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: _this.expiredTime, // 时间戳，单位秒，如：1580000000
          });
        }
      });
      let fileType = file.name.substring(file.name.lastIndexOf('.') + 0);
      cos.putObject({
        Bucket: this.bucket, /* 必须 */
        Region: this.region,     /* 存储桶所在地域，必须字段 */
        Key: 'TMP/' + getUuiD() + fileType,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: file, // 上传文件对象
        onProgress: function (progressData) {
        }
      }, function (err, data) {
        if (!err && data) {
          Toast.clear()
          _this.businessLicenseShow = false;
          _this.businessLicense = "https://" + data.Location
        } else {
          Toast.fail('上传失败');
        }
      })
    },
    isOverSize(file) {
      if (file.size > 5242880) {
        Toast("请上传小于5M的图片")
        this.fileSize = false;
        return this.fileSize;
      }
      this.fileSize = true;
    },
  },
  mounted() {
    document.title = this.title
    this.isAudit = this.$route.query.isAudit;
    if (this.isAudit){
      let partnerCorp = JSON.parse(localStorage.getItem("partnerCorp"))
      if (partnerCorp.remark && partnerCorp.isAudit === 0) {
        let remark = JSON.parse(partnerCorp.remark)
        this.businessLicenseShow = false;
        this.businessLicense = partnerCorp.businessLicense;
        this.partnerCorpName = partnerCorp.partnerCorpName;
        this.creditCode = partnerCorp.creditCode;
        this.contacts = partnerCorp.contacts;
        this.mobile = partnerCorp.mobile;
        for (let i = 0; i < remark.length; i++) {
          switch (remark[i].value) {
            case 1:
              this.businessLicenseShow = true;
              this.businessLicense = "";
              this.businessLicenseAuth = true;
              this.creditCodeShow = true;
              this.creditCode = "";
              this.partnerCorpNameShow = true;
              this.partnerCorpName = "";
              break;
            case 2:
              this.contactsShow = true;
              this.contacts = "";
              this.contactsMobile = "";
              break;
          }
        }
      }
    }
    getTempSecretKey().then(res => {
      this.bucket = res.bucket;
      this.expiredTime = res.expiredTime;
      this.region = res.region;
      this.sessionToken = res.sessionToken;
      this.startTime = res.startTime;
      this.tmpSecretId = res.tmpSecretId;
      this.tmpSecretKey = res.tmpSecretKey;
    })
  }
}
</script>

<style scoped>

</style>