<template>
  <div>
    <nav-bar :title="title"/>
    <global-block card>
      <van-form @submit="onSubmit">
        <!-- 通过 pattern 进行正则校验 -->
        <van-field
            v-model="reportName"
            name="reportName"
            label="企业名称"
            placeholder="请输入企业名称"
            :rules="[{ required: true, message: '请输入企业名称' }]"
        />
        <!-- 通过 validator 进行函数校验 -->
        <van-field
            v-model="contacts"
            name="contacts"
            label="企业联系人"
            placeholder="请输入企业联系人"
            :rules="[{ required: true, message: '请输入企业联系人' }]"
        />
        <!-- 通过 validator 进行异步函数校验 -->
        <van-field
            v-model="mobile"
            name="mobile"
            label="联系人电话"
            placeholder="请输入联系人电话"
            type="digit"
            maxlength="11"
            :rules="[{ required: true, message: '请输入联系人电话' }]"
        />
        <van-field
            v-model="contactsPost"
            name="contactsPost"
            label="联系人职位"
            placeholder="请输入联系人职位"
            :rules="[{ required: true, message: '请输入联系人职位' }]"
        />
        <van-field
            v-model="remark"
            name="remark"
            rows="2"
            autosize
            label="备注"
            type="textarea"
            maxlength="50"
            placeholder="请输入报企业经营范围，用于业务员了解"
            show-word-limit
        />
        <div style="margin: 16px;">
          <van-button round block type="info" native-type="submit">提交</van-button>
        </div>
      </van-form>
    </global-block>
  </div>
</template>

<script>
import {Form, Field, Toast, Dialog} from 'vant';
import GlobalBlock from "@/components/Global-Block";
import NavBar from "@/components/nav/Nav-Bar";
import {formatParams, formatParamsTo, request} from "@/static/utils/api";
export default {
  name: "corp-report",
  data() {
    return {
      title: '企业报备',
      reportName: '',
      contacts:'',
      mobile: '',
      contactsPost:'',
      remark: '',
      cooperationDirection: '',
      reportType: 1,
      partnerType: '',
    };
  },
  components:{
    [Form.name]:Form,
    [Field.name]:Field,
    GlobalBlock,
    NavBar
  },
  methods: {
    onSubmit(values) {
      if (!/\d{11}/.test(values.mobile)) {
        Toast("手机号输入有误")
        return false;
      }
      values.reportType = this.reportType;
      values.partnerType = this.partnerType;
      request({
        url: '/partnerReport/addPartnerReport',
        data: JSON.stringify(this.partnerType === 0 ? formatParams(values) : formatParamsTo(values))
      }).then(res => {
        if (res) {
          Dialog.alert({
            title: '提示',
            message: '报备成功！后续会有相关的业务员跟进，报备有效期限为三个月，三个月后则需要重新报备。',
          }).then(() => {
            this.$router.push({path: '/home', query: {partnerType: this.partnerType}});
          });
        }
      }).catch(err => {
        console.log(err)

      })
    }
  },
  mounted() {
    document.title = this.title
    this.partnerType = parseInt(localStorage.getItem("partnerType"));
  }
}
</script>

<style scoped>

</style>