<template>
  <div>
    <NavBar :title="title"/>
    <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o">
      我的客户为已合作的客户
    </van-notice-bar>
    <global-block card>
      <van-tabs v-model="active" @click="onTabClick">
        <van-tab title="个人客户">
          <van-cell><strong style="padding: 0 15px 0 15px">名称<span style="float: right">电话</span></strong></van-cell>
          <van-pull-refresh v-model="personRefresh" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="getPersonClient"
            >
              <van-cell v-for="(item,index) in personClient" :key="index" :title="item.clientName">{{ item.mobile }}
              </van-cell>
            </van-list>
            <van-empty v-if="personClientIsEmpty"
                class="custom-image"
                image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
                description="这里是空的~~"
            />
          </van-pull-refresh>
        </van-tab>
        <van-tab title="企业客户">
          <van-cell><strong style="padding: 0 15px 0 15px">企业名称<span style="float: right">联系人</span></strong></van-cell>
          <van-pull-refresh v-model="corpRefresh" @refresh="onRefresh">
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="getCorpClient"
            >
              <van-cell v-for="(item,index) in corpClient" :key="index" :title="item.corpName">
                {{ item.corpContact }}
              </van-cell>
            </van-list>
            <van-empty v-if="corpClientIsEmpty"
                       class="custom-image"
                       image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
                       description="这里是空的~~"
            />
          </van-pull-refresh>
        </van-tab>
      </van-tabs>
    </global-block>
  </div>
</template>

<script>
import NavBar from "@/components/nav/Nav-Bar";
import GlobalBlock from "@/components/Global-Block";
import {formatParams, formatParamsTo, request} from "@/static/utils/api";
import {Toast} from "vant";

export default {
  name: "my-client",
  data() {
    return {
      title: '我的客户',
      active: 0,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      activeNames: ['1'],
      personRefresh: false,
      corpRefresh: false,
      corpClient: [],
      personClient: [],
      partnerType: '',
      personClientIsEmpty:false,
      corpClientIsEmpty:false,

      // show: true,
    };
  },
  components: {
    NavBar,
    GlobalBlock
  },
  methods: {
    onTabClick(name, title) {
      if (name === 0) {
        this.personClient = [];
        this.getPersonClient();
      } else {
        this.corpClient = [];
        this.getCorpClient();
      }

    },
    getPersonClient() {
      request({
        url: '/partnerClient/getPartnerClientByPartnerIsCorpId',
        data: JSON.stringify(this.partnerType === 0 ? formatParams({partnerType: this.partnerType}) : formatParamsTo({partnerType: this.partnerType}))
      }).then(res => {
        if (res) {
          if (this.personRefresh) {
            this.personClient = [];
            this.personRefresh = false;
          }
          if (res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              this.personClient.push(res[i]);
            }
          } else {
            this.personClientIsEmpty = true;
          }
          this.loading = false;
          this.finished = true;
        }
      }).catch(err => {
        console.log(err)
      })
    },
    getCorpClient() {

      request({
        url: '/corp/corpBByIdS',
        data: JSON.stringify(this.partnerType === 0 ? formatParams({partnerType: this.partnerType}) : formatParamsTo({partnerType: this.partnerType}))
      }).then(res => {
        if (res) {
          if (this.corpRefresh) {
            this.corpClient = [];
            this.corpRefresh = false;
          }
          if (res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              this.corpClient.push(res[i]);
            }
          } else {
            this.corpClientIsEmpty = true;
          }
          this.loading = false;
          this.finished = true;
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.active === 0 ? this.getPersonClient() : this.getCorpClient()
    },
  },
  mounted() {
    document.title = this.title
    this.partnerType = parseInt(localStorage.getItem("partnerType"));

  }
}
</script>

<style lang="less">
</style>