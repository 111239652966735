<template>
  <div>
    <nav-bar :title="title"/>
    <GlobalBlock card>
      <van-field
          v-model="partnerName"
          name="partnerName"
          label="用户名"
          readonly
      />
      <van-field
          v-model="mobile"
          name="mobile"
          label="手机号码"
          readonly
      />
      <van-collapse v-model="activeNames">
        <van-collapse-item title="免冠照" name="1"><img :src="bareheadedPhoto" style="width: 100%;height: auto"/>
        </van-collapse-item>
        <van-collapse-item title="身份证-正" name="2"><img :src="idCardFront" style="width: 100%;height: auto"/>
        </van-collapse-item>
        <van-collapse-item title="身份证-反" name="3"><img :src="idCardBack" style="width: 100%;height: auto"/>
        </van-collapse-item>
      </van-collapse>
      <van-cell title="设置密码" is-link to="/setPassword"/>
      <van-cell title="诚信纳税承诺书"  is-link :url=promise />
      <van-cell title="代开发票风险提示"  is-link :url=riskWarning />
      <div style="margin: 16px;">
        <van-button block type="info" plain @click="logout">退出登录</van-button>
      </div>

    </GlobalBlock>
  </div>
</template>

<script>
import {Form, Field, Dialog} from 'vant';
import NavBar from "@/components/nav/Nav-Bar";
import GlobalBlock from "@/components/Global-Block";

export default {
  name: "my-person-data",
  data() {
    return {
      title:'我的信息',
      activeNames: [],
      partnerName: '张三',
      mobile: '13000000000',
      idCardFront: '',
      idCardBack: '',
      bareheadedPhoto: '',
      promise:'',
      riskWarning:'',
      id:0,
    };
  },
  components: {
    GlobalBlock,
    NavBar,
    [Form.name]: Form,
    [Field.name]: Field
  },
  mounted() {
    document.title = this.title
    let partner = JSON.parse(localStorage.getItem("partner"));
    this.partnerName = partner.partnerName;
    this.id = partner.id;
    this.mobile = partner.mobile;
    this.idCardFront = partner.idCardFront + "?watermark/2/text/5LuF5L6b5Yib5om25LyB6KeE5a6d5YW25LuW5peg5pWI/fill/I0ZGRkZGRg==/fontsize/20/dissolve/50/gravity/northeast/dx/20/dy/20/batch/1/degree/45";
    this.idCardBack = partner.idCardBack + "?watermark/2/text/5LuF5L6b5Yib5om25LyB6KeE5a6d5YW25LuW5peg5pWI/fill/I0ZGRkZGRg==/fontsize/20/dissolve/50/gravity/northeast/dx/20/dy/20/batch/1/degree/45";
    this.bareheadedPhoto = partner.bareheadedPhoto + "?watermark/2/text/5LuF5L6b5Yib5om25LyB6KeE5a6d5YW25LuW5peg5pWI/fill/I0ZGRkZGRg==/fontsize/20/dissolve/50/gravity/northeast/dx/20/dy/20/batch/1/degree/45";
    this.promise = partner.promise;
    this.riskWarning = partner.riskWarning;
  },
  methods: {
    logout() {
      Dialog.confirm({
        title: '提示',
        message: '您确定要退出登录吗？',
      })
      .then(() => {
          localStorage.clear();
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3caf51bfc7052700&redirect_uri=http%3A%2F%2Fpartner.chuangfushuzi.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect"
      }).catch(() => {
        console.log("cancel")
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    let lastRouters = JSON.parse(sessionStorage.getItem("lastRouters"));
    console.log(lastRouters)
    lastRouters.push(this.$route.path)
    lastRouters =  Array.from(new Set(lastRouters));
    sessionStorage.setItem("lastRouters",JSON.stringify(lastRouters));
    next()
  }
}
</script>

<style scoped>

</style>
