<template>
  <div>
    <van-nav-bar
        :title="title"
        left-arrow
        @click-left="onClickLeft"
    />
  </div>
</template>

<script>
import {NavBar} from 'vant';

export default {
  components: {
    [NavBar.name]: NavBar
  },
  data() {
    return {
      isPath: false,
    };
  },
  props: {
    title: String
  },
  methods: {
    onClickLeft() {
      if (this.isPath) {
        let lastRouters = JSON.parse(sessionStorage.getItem("lastRouters"));
        console.log(lastRouters)
        for (let i = 0; i < lastRouters.length; i++) {
          if (lastRouters[lastRouters.length - 1] === lastRouters[i]) {
            let path = lastRouters[i];
            lastRouters.pop()
            sessionStorage.setItem("lastRouters", JSON.stringify(lastRouters));
            this.$router.replace(path)
          }
        }
      } else {
        this.$router.replace(window.sessionStorage.getItem('lastRouter'))
      }
    }
  },
  mounted() {
    if (this.$route.path === '/signPreview' || this.$route.path === '/signAffirm' || this.$route.path === '/setPassword') {
      this.isPath = true;
    }

  }
}
</script>

<style lang="less">
.demo-nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  background-color: #fff;

  &__title {
    font-weight: 600;
    font-size: 17px;
    text-transform: capitalize;
  }

  &__back {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}
</style>
