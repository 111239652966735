<template>
  <div id="app">
      <router-view></router-view>
    <van-number-keyboard safe-area-inset-bottom />
  </div>
</template>

<script>
import {Dialog} from "vant";

export default {
  components: {
  },
  mounted() {
    // console.log(this.$route.meta.title)
    document.title = this.$route.meta.title
    if (this.$route.path !== '/login') {
      let partnerType = localStorage.getItem("partnerType");
      let partner = JSON.parse(localStorage.getItem("partner"));
      let token = JSON.parse(localStorage.getItem("token"));
      let partnerCorp = JSON.parse(localStorage.getItem("partnerCorp"));
      if (partnerType == null || token == null) {
        Dialog.alert({
          title: '提示',
          message: '您还未登录，请先登录',
        }).then(() => {
          window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3caf51bfc7052700&redirect_uri=http%3A%2F%2Fpartner.chuangfushuzi.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect"
        });
      }
    }
  }

}
</script>

<style lang="less">
@import 'common/style/var';
@import 'common/style/base';


body {
  min-width: 100vw;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
</style>
