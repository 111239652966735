<template>
  <div>
    <nav-bar :title="title"/>
    <global-block card>
      <van-cell><strong>来源<span style="float: right">收益</span></strong></van-cell>
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <van-list
            v-model="loading"
            :finished="finished"
            @load="getPartnerFeeRecordList"
        >
          <van-cell v-for="(item,index) in feeRecord" :key="index">
            <p><strong>{{ item.costName }}</strong><span style="float: right">{{ item.promotionExpenses / 100 }}元</span>
            </p>
            <p>{{ item.costExplain }}月份业务总金额共计：{{ item.businessAmount / 100 }}元</p>
            <div style="float: right;margin-top: 10px" v-if="item.status===1">
              <van-button type="danger" size="small" style="margin-right: 10px" @click="updPartnerFeeRecord(item.id,2)">
                报错
              </van-button>
              <van-button type="primary" size="small" @click="updPartnerFeeRecord(item.id,3,1)">确认</van-button>
            </div>
            <div style="float: right;margin-top: 10px" v-if="item.status===2">
              <van-tag type="danger " size="large" plain>已报错</van-tag>
            </div>
          </van-cell>
        </van-list>
        <van-empty v-if="feeRecordIsEmpty"
                   class="custom-image"
                   image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
                   description="这里是空的~~"
        />
      </van-pull-refresh>
      <van-popup v-model="show" :round="true" style="width: 80%;text-align: center" :closeable="true">
        <van-cell title="请输入原因"/>
        <van-field
            v-model="reason"
            rows="2"
            autosize
            type="textarea"
            placeholder="请输入原因"
            maxlength="50"
            show-word-limit
        />
        <van-button type="warning" size="small" style="margin: 10px" @click="cancel">取消</van-button>
        <van-button type="primary" size="small" style="margin: 10px"
                    @click="updPartnerFeeRecord(partnerFeeRecordId,status,1)">确认
        </van-button>
      </van-popup>

    </global-block>

  </div>
</template>

<script>
import GlobalBlock from "@/components/Global-Block";
import NavBar from "@/components/nav/Nav-Bar";
import {formatParams, formatParamsTo, request} from "@/static/utils/api";
import {Toast} from "vant";

export default {
  data() {
    return {
      title: '我的收益',
      show: false,
      isLoading: false,
      loading: false,
      finished: false,
      partnerType: '',
      feeRecord: [],
      partnerFeeRecordId: '',
      reason: '',
      status: '',
      count: 0,
      feeRecordIsEmpty:false,
    }
  },
  name: "My-earnings",
  components: {
    GlobalBlock,
    NavBar
  },
  methods: {
    reportError(id) {

    },
    cancel() {
      this.show = false;
    },
    getPartnerFeeRecordList() {
      request({
        url: '/partnerFeeRecord/getPartnerFeeRecordList',
        data: JSON.stringify(this.partnerType === 0 ? formatParams({partnerType: this.partnerType}) : formatParamsTo({partnerType: this.partnerType}))
      }).then(res => {
        if (res) {
          if (this.isLoading) {
            this.feeRecord = [];
            this.isLoading = false;
          }
          if (res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              this.feeRecord.push(res[i]);
            }
          } else {
            this.feeRecordIsEmpty = true;
          }
          this.loading = false;
          this.finished = true;
        }
      }).catch(err => {
        console.log(err)
      })
    },
    onRefresh() {
      this.feeRecord = [];
      // 清空列表数据
      this.finished = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.getPartnerFeeRecordList();

    },
    updPartnerFeeRecord(id, status, affirm) {
      if (status === 2 && affirm !== 1) {
        this.show = true;
      }
      this.partnerFeeRecordId = id;
      this.status = status;
      if (affirm === 1) {
        request({
          url: '/partnerFeeRecord/updPartnerFeeRecord',
          data: JSON.stringify(this.partnerType === 0 ? formatParams({
            partnerType: this.partnerType,
            partnerFeeRecordId: id,
            status: this.status,
            reason: this.reason
          }) : formatParamsTo({
            partnerType: this.partnerType,
            partnerFeeRecordId: id,
            status: this.status,
            reason: this.reason
          }))
        }).then(res => {
          if (res) {
            this.show = false;
            this.onRefresh();
          }
        }).catch(err => {
          console.log(err)
        })
      }
    }
  },
  mounted() {
    document.title = this.title
    this.partnerType = parseInt(localStorage.getItem("partnerType"));
    // this.getPartnerFeeRecordList();
  }
}
</script>

<style scoped>

</style>