import Vue from 'vue'
import App from './App.vue'
import router from './router/router' //导入路由对象
import store from "@/store";
import {
    // Actionsheet,
    AddressEdit,
    AddressList,
    Area,
    Badge,
    // BadgeGroup,
    Button,
    Divider,
    Card,
    Cell,
    CellGroup,
    Checkbox,
    CheckboxGroup,
    Circle,
    Col,
    Collapse,
    CollapseItem,
    ContactCard,
    ContactEdit,
    ContactList,
    Coupon,
    CouponCell,
    CouponList,
    DatetimePicker,
    Dialog,
    Empty,
    Field,
    GoodsAction,
    Grid,
    GridItem,
    // GoodsActionBigBtn,
    // GoodsActionMiniBtn,
    Icon,
    Image,
    // VanImage,
    ImagePreview,
    Info,
    Lazyload,
    List,
    Loading,
    Locale,
    NavBar,
    NoticeBar,
    Notify,
    NumberKeyboard,
    Overlay,
    Pagination,
    Panel,
    PasswordInput,
    Picker,
    Popup,
    Progress,
    PullRefresh,
    Radio,
    RadioGroup,
    Rate,
    Row,
    Search,
    Sku,
    Slider,
    Step,
    Stepper,
    Steps,
    SubmitBar,
    Swipe,
    SwipeCell,
    SwipeItem,
    Switch,
    SwitchCell,
    Tab,
    Tabbar,
    TabbarItem,
    Tabs,
    Tag,
    Toast,
    TreeSelect,
    Uploader
} from 'vant'

Vue
    .use(AddressEdit)
    .use(AddressList)
    .use(Area)
    .use(Badge)
    .use(Button)
    .use(Card)
    .use(Cell)
    .use(CellGroup)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Circle)
    .use(Col)
    .use(Collapse)
    .use(CollapseItem)
    .use(ContactCard)
    .use(ContactEdit)
    .use(ContactList)
    .use(Coupon)
    .use(CouponCell)
    .use(CouponList)
    .use(DatetimePicker)
    .use(Dialog)
    .use(Divider)
    .use(Empty)
    .use(Field)
    .use(GoodsAction)
    .use(Grid)
    .use(GridItem)
    // .use(GoodsActionBigBtn)
    // .use(GoodsActionMiniBtn)
    .use(Icon)
    .use(Image)
    // .use(VanImage)
    .use(ImagePreview)
    .use(Info)
    .use(Lazyload)
    .use(List)
    .use(Loading)
    .use(Locale)
    .use(NavBar)
    .use(NoticeBar)
    .use(Notify)
    .use(NumberKeyboard)
    .use(Overlay)
    .use(Pagination)
    .use(Panel)
    .use(PasswordInput)
    .use(Picker)
    .use(Popup)
    .use(Progress)
    .use(PullRefresh)
    .use(Radio)
    .use(RadioGroup)
    .use(Rate)
    .use(Row)
    .use(Search)
    .use(Sku)
    .use(Slider)
    .use(Step)
    .use(Stepper)
    .use(Steps)
    .use(SubmitBar)
    .use(Swipe)
    .use(SwipeCell)
    .use(SwipeItem)
    .use(Switch)
    .use(SwitchCell)
    .use(Tab)
    .use(Tabbar)
    .use(TabbarItem)
    .use(Tabs)
    .use(Tag)
    .use(Toast)
    .use(TreeSelect)
    .use(Uploader)


let partner = JSON.parse(localStorage.getItem("partner"));
let partnerCorp = JSON.parse(localStorage.getItem("partnerCorp"));
if (partner == null && partnerCorp == null) {
    let path = window.location.pathname
    switch (path) {
        case '/login':
            break;
        default:
            // console.log(1)
            // Dialog.alert({
            //     title: '提示',
            //     message: '登录已过期，请重新登录',
            // }).then(() => {
            //     window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3caf51bfc7052700&redirect_uri=http%3A%2F%2Fpartner.chuangfushuzi.com%2Flogin&response_type=code&scope=snsapi_userinfo&state=STATE&#wechat_redirect"
            // });
            break;
    }
}

window.addEventListener('popstate',function () {
    history.pushState(null,null,document.URL);
})



new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
