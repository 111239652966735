<template>
  <div>
    <nav-bar :title="title"/>
    <GlobalBlock card>
      <van-form @submit="onSubmit">
        <van-cell title="上传证件"/>
        <van-field name="uploader" label="身份证正面">
          <template #input>
            <van-uploader :after-read="afterRead" name="FRONT" :max-size="isOverSize" accept="image/*">
              <img src="@/static/images/id-card-front.png" v-show="frontShow" style="width: 100%;height: 150px">
              <img :src="idCardFront" v-show="!frontShow" style="width: 100%;height: 150px">
              <p style="color: #FF0000FF" v-show="frontAuth">身份证正面未通过审核</p>
            </van-uploader>
          </template>
        </van-field>
        <van-field name="uploader1" label="身份证反面">
          <template #input>
            <van-uploader :after-read="afterRead" name="BACK" :max-size="isOverSize" accept="image/*">
              <img src="@/static/images/id-card-back.png" v-show="backShow" style="width: 100%;height: 150px">
              <img :src="idCardBack" v-show="!backShow" style="width: 100%;height: 150px">
              <p style="color: #FF0000FF" v-show="backAuth">身份证反面未通过审核</p>
            </van-uploader>
          </template>
        </van-field>
        <van-field name="uploader1" label="免冠照">
          <template #input>
            <van-uploader :after-read="afterRead" name="IDPHOTO" :max-size="isOverSize" accept="image/*">
              <img src="@/static/images/ID-Photo.png" v-show="photoShow" style="width: 100%;height: 150px">
              <img :src="bareheadedPhoto" v-show="!photoShow" style="width: 100%;height: 150px">
              <p style="color: #FF0000FF" v-show="photoAuth">免冠照未通过审核</p>
            </van-uploader>
          </template>
        </van-field>
        <van-cell title="填写基本信息"/>
        <van-field
            v-model="mobile"
            name="mobile"
            label="手机号码"
            readonly
        />
        <van-field
            v-model="openingBank"
            name="openingBank"
            label="开户行"
            placeholder="银行卡开户行"
            :rules="[{ required: true, message: '请输入银行卡开户行' }]"

        />

        <van-field
            v-model="bankCard"
            name="bankCard"
            label="银行卡号"
            placeholder="银行卡号"
            :rules="[{ required: true, message: '请输入银行卡号' }]"

        />

        <van-field
            v-model="partnerName"
            name="partnerName"
            label="姓名"
            placeholder="该区域为智能识别"
            :readonly="front"
            :rules="[{ required: true, message: '请输入姓名' }]"

        />

        <van-field name="radio" label="性别">
          <template #input>
            <van-radio-group direction="horizontal" v-model="sex">
              <van-radio name="1">男</van-radio>
              <van-radio name="0">女</van-radio>
            </van-radio-group>
          </template>
        </van-field>
        <van-field
            v-model="nation"
            name="nation"
            label="名族"
            placeholder="该区域为智能识别"
            :readonly="front"
            :rules="[{ required: true, message: '请输入名族' }]"

        />

        <van-field
            v-model="birth"
            name="birth"
            label="出生日期"
            placeholder="该区域为智能识别"
            :readonly="front"
            :rules="[{ required: true, message: '请输入出生日期' }]"

        />

        <van-field
            v-model="idCard"
            name="idCard"
            label="身份证号码"
            placeholder="该区域为智能识别"
            :readonly="front"
            :rules="[{ required: true, message: '请输入身份证号码' }]"

        />

        <van-field
            v-model="authority"
            name="authority"
            label="签发机关"
            placeholder="该区域为智能识别"
            :readonly="back"
            :rules="[{ required: true, message: '请输入签发机关' }]"

        />

        <van-field
            v-model="validDate"
            name="validDate"
            label="证件有效期"
            placeholder="该区域为智能识别"
            :readonly="back"
            :rules="[{ required: true, message: '请输入证件有效期' }]"
        />

        <van-field
            v-model="address"
            :readonly="front"
            rows="2"
            autosize
            name="address"
            label="详细地址"
            type="textarea"
            placeholder="该区域为智能识别"
            show-word-limit
            :rules="[{ required: true, message: '请输入详细地址' }]"
        />

        <div style="margin: 16px;">
          <van-button round block type="info" v-show="button" native-type="submit">下一步</van-button>
          <van-button round block native-type="button" v-show="!button" type="primary" @click="show=true">录制签署名
          </van-button>
        </div>
      </van-form>
      <van-popup v-model="show" position="bottom" style="height: 40%;padding-bottom: 50px">
        <signature/>
      </van-popup>
    </GlobalBlock>
  </div>
</template>

<script>
import NavBar from "@/components/nav/Nav-Bar";
import GlobalBlock from "@/components/Global-Block";
import Signature from "@/views/signature/signature";
import {Dialog, Form, Toast} from 'vant';
import COS from "cos-js-sdk-v5";
import {formatParams, getUuiD, request} from "@/static/utils/api";
import {getTempSecretKey} from "@/static/utils/common";

export default {
  name: "person-auth",
  data() {
    return {
      title:'个人认证',
      frontShow: true,
      button: true,
      backShow: true,
      photoShow: true,
      photoAuth: false,
      frontAuth: false,
      backAuth: false,
      front: true,
      back: true,
      show: false,
      partnerId: '',
      bucket: '',
      expiredTime: '',
      region: '',
      sessionToken: '',
      startTime: '',
      tmpSecretId: '',
      tmpSecretKey: '',
      fileSize: true,
      partnerName: '',
      sex: '1',
      nation: '',
      birth: '',
      idCard: '',
      authority: '',
      validDate: '',
      address: '',
      openingBank: '',
      bankCard: '',
      mobile: '',
      idCardFront: '',
      idCardBack: '',
      bareheadedPhoto: '',
      isSignAuth: 1
    };
  },
  components: {
    [Form.name]: Form,
    NavBar,
    GlobalBlock,
    Signature,
  },
  methods: {
    onSubmit(values) {
      if (this.idCardFront === '') {
        Toast("请上传身份证正面")
        return false;
      }
      if (this.idCardBack === '') {
        Toast("请上传身份证反面")
        return false;
      }
      if (this.bareheadedPhoto === '') {
        Toast("请上传免冠照")
        return false;
      }

      values.idCardFront = this.idCardFront;
      values.idCardBack = this.idCardBack;
      values.bareheadedPhoto = this.bareheadedPhoto;
      values.anewSubmit = this.anewSubmit;
      request({
        url: '/partner/addPartnerIdCard',
        data: JSON.stringify(formatParams(values))
      }).then(res => {
        if (res) {
          this.$store.commit('handlePartner', res)
          switch (this.isSignAuth) {
            case 0:
              Dialog.alert({
                title: '提示',
                message: '您的录制的签署名未通过审核，需要重新录制',
              }).then(() => {
                this.button = false;
                this.show = true;
              });
              break;
            case 1:
              this.$router.replace('/authFeedback');
              break;
            case 2:
              Dialog.alert({
                title: '提示',
                message: '根据相关规定，进行流程业务签署需要录制签署名，点击可前往录制签署名',
              }).then(() => {
                this.button = false;
                this.show = true;
              });
              break;
          }
        }
      }).catch(err => {
        console.log(err);
      })
    },
    isOverSize(file) {
      if (file.size > 5242880) {
        Toast("请上传小于5M的图片")
        this.fileSize = false;
        return this.fileSize;
      }
      this.fileSize = true;
    },
    afterRead(file, detail) {
      if (this.fileSize) {
        // 自定义加载图标
        Toast.loading({
          message: '上传中...',
          forbidClick: true,
          loadingType: 'spinner',
          duration: 0
        });
        this.uploadIdCard(file.file, detail.name);
      }
    },
    uploadIdCard(file, type) {
      let _this = this;
      const cos = new COS({
        getAuthorization: function (options, callback) {
          callback({
            TmpSecretId: _this.tmpSecretId,
            TmpSecretKey: _this.tmpSecretKey,
            SecurityToken: _this.sessionToken,
            // 建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
            StartTime: _this.startTime, // 时间戳，单位秒，如：1580000000
            ExpiredTime: _this.expiredTime, // 时间戳，单位秒，如：1580000000
          });
        }
      });
      let fileType = file.name.substring(file.name.lastIndexOf('.') + 0);
      cos.putObject({
        Bucket: this.bucket, /* 必须 */
        Region: this.region,     /* 存储桶所在地域，必须字段 */
        Key: 'TMP/' + getUuiD() + fileType,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: file, // 上传文件对象
        onProgress: function (progressData) {
        }
      }, function (err, data) {
        if (!err && data) {
          Toast.clear()
          let idCardUrl = "https://" + data.Location
          if (type === 'IDPHOTO') {
            _this.photoShow = false;
            _this.bareheadedPhoto = idCardUrl;
          } else {
            _this.identificationIdCard(idCardUrl, type)

          }
        } else {
          Toast.fail('上传失败');
        }
      })
    },
    identificationIdCard(idCardUrl, type) {
      request({
        url: '/partner/identificationIdCard',
        data: JSON.stringify(formatParams({
          type: type,
          idCardUrl: idCardUrl
        }))
      }).then(res => {
        switch (type) {
          case 'FRONT':
            this.frontShow = false;
            this.front = false;
            this.idCardFront = idCardUrl;
            this.partnerName = res.Name;
            this.idCard = res.IdNum;
            this.birth = res.Birth;
            this.address = res.Address;
            this.nation = res.Nation;
            if (res.Sex && res.Sex === '男') {
              this.sex = "1";
            } else {
              this.sex = "0";
            }
            break;
          case 'BACK':
            this.back = false;
            this.backShow = false;
            this.idCardBack = idCardUrl;
            this.authority = res.Authority;
            this.validDate = res.ValidDate;
            break;
          default:
            Toast("未识别出身份证信息")
            break;
        }
        // Toast("发送成功")
      }).catch(err => {
        console.log(err);
        switch (type) {
          case 'FRONT':
            this.idCardFront = '';
            this.front = true;
            this.frontShow = true;
            break;
          case 'BACK':
            this.idCardBack = '';
            this.back = true;
            this.backShow = true;
            break;
          default:
            Toast("未识别出身份证信息")
            break;
        }
      })
    }
  },
  mounted() {
    document.title = this.title
    this.isAudit = this.$route.query.isAudit;
    if (this.isAudit) {
      let partner = JSON.parse(localStorage.getItem("partner"))
      this.anewSubmit = 1;
      if (partner.remark && partner.isAudit === 0) {
        let remark = JSON.parse(partner.remark)
        this.frontShow = false;
        this.backShow = false;
        this.photoShow = false;
        this.idCardFront = partner.idCardFront;
        this.idCardBack = partner.idCardBack;
        this.bareheadedPhoto = partner.bareheadedPhoto;
        this.openingBank = partner.openingBank;
        this.bankCard = partner.bankCard;
        this.partnerName = partner.partnerName;
        this.nation = partner.nation;
        this.birth = partner.birth;
        this.idCard = partner.idCard;
        this.sex = partner.sex;
        this.authority = partner.authority;
        this.validDate = partner.validDate;
        this.address = partner.address;
        for (let i = 0; i < remark.length; i++) {
          switch (remark[i].value) {
            case 1:
              this.photoShow = true;
              this.photoAuth = true;
              break;
            case 2:
              this.frontShow = true;
              this.frontAuth = true;
              break;
            case 3:
              this.backShow = true;
              this.backAuth = true;
              break;
            case 4:
              this.isSignAuth = 0;
              break;
          }
        }
      }
    } else {
      this.isSignAuth = 2
    }
    let partner = JSON.parse(localStorage.getItem("partner")) || this.$store.getters.partner;
    this.mobile = partner.mobile;
    getTempSecretKey().then(res => {
      this.bucket = res.bucket;
      this.expiredTime = res.expiredTime;
      this.region = res.region;
      this.sessionToken = res.sessionToken;
      this.startTime = res.startTime;
      this.tmpSecretId = res.tmpSecretId;
      this.tmpSecretKey = res.tmpSecretKey;
    })

  }
}
</script>

<style scoped>

</style>
